import React from 'react'
import links from 'links'

import { useSubscribeOffer } from 'modules/subscription'
import SubscribeHeaderBannerBaseAb from 'compositions/banners/SubscribeHeaderBannerBaseAb/SubscribeHeaderBannerBaseAb'

import messages from './messages'


const textByOffer: Partial<Record<SubscriptionModule.OfferType, Intl.Message>> = {
  freeProduct: messages.freeProduct,
  discount: messages.discount,
}

type LandingSubscriptionHeaderBannerAbProps = {
  placement?: 'Header' | 'Under logo'
  isStatic?: boolean
}

// this banner is used on landings and register page
const LandingSubscriptionHeaderBannerAb: React.FC<LandingSubscriptionHeaderBannerAbProps> = ({ placement = 'Header', isStatic }) => {
  const {
    isFetching,
    discountText,
    offerType,
    cookieCouponCode,
    couponCode,
    eventDiscountText,
    handleSubscribeClick,
  } = useSubscribeOffer()

  // ATTN we don't show default coupon if banner is static, because it's not saved
  if (isStatic && !cookieCouponCode) {
    return null
  }

  // ATTN we show only limited amount of offers here
  if (!textByOffer[offerType]) {
    return null
  }

  const title = {
    ...textByOffer[offerType],
    values: {
      discount: discountText,
    },
  }

  return (
    <SubscribeHeaderBannerBaseAb
      title={title}
      loading={isFetching}
      isStatic={isStatic}
      trackParams={{
        placement,
        content: 'Subscribe',
        action: 'link',
        link: links.subscribe,
        discount: eventDiscountText,
        coupon: couponCode,
      }}
      onClick={handleSubscribeClick}
    />
  )
}


export default LandingSubscriptionHeaderBannerAb
