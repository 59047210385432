import React from 'react'
import { useDevice } from 'device'
import { Message } from 'intl'
import links from 'links'
import { twcx } from 'helpers'

import { Icon, Text } from 'components/dataDisplay'

import HeaderBannerContainer, { type HeaderBannerContainerProps } from 'compositions/banners/HeaderBannerContainer/HeaderBannerContainer'

import Countdown from './components/Countdown/Countdown'


export type SubscribeHeaderBannerBaseProps = Pick<HeaderBannerContainerProps, 'to' | 'trackParams' | 'loading' | 'onClick'> & {
  className?: string
  textClassName?: string
  title?: Intl.Message
  isStatic?: boolean
  withArrow?: boolean
  withCountdown?: boolean
  withMilliseconds?: boolean
  countdownCookieName?: string
}

// base banner without any logic about offer copy and countdown
const SubscribeHeaderBannerBase: React.FC<SubscribeHeaderBannerBaseProps> = (props) => {
  const {
    className,
    textClassName,
    title,
    to = links.subscribe,
    trackParams,
    loading,
    onClick,
    isStatic,
    withArrow,
    withCountdown,
    withMilliseconds,
    countdownCookieName,
  } = props

  const { isMobile } = useDevice()

  const bannerProps: HeaderBannerContainerProps = {
    className: twcx('bg-gold-50', className),
    height: isMobile ? 48 : 64,
    'data-testid': 'subscribeBanner',
    loading,
  }

  if (!isStatic) {
    bannerProps.to = to
    bannerProps.trackParams = trackParams
    bannerProps.onClick = onClick
  }

  return (
    <HeaderBannerContainer {...bannerProps}>
      <Text className={textClassName} style={isMobile ? 'sh6' : 'sh5'} align="left">
        <span className="whitespace-nowrap"><Message value={title} /></span>
        {
          withCountdown && (
            <Countdown cookieName={countdownCookieName} withMilliseconds={withMilliseconds} />
          )
        }
      </Text>
      {
        withArrow && (
          <Icon className="ml-12 flex-none" name="other/long-arrow-right" />
        )
      }
    </HeaderBannerContainer>
  )
}


export default SubscribeHeaderBannerBase
