import { useDevice } from 'device'
import cx from 'classnames'
import { useLandingSetup } from 'hooks'

import { useUser } from 'modules/user'

import { BackgroundImage, Text } from 'components/dataDisplay'

import messages from './messages'

import mobileBackgroundImage from './images/mobile-background.svg'
import descktopBackgroundImage from './images/desktop-background.svg'


const TwelveDaysBanner = () => {
  const { isMobile } = useDevice()
  const { country } = useUser()

  const {
    discountAmount,
    discountPercent,
  } = useLandingSetup({
    couponCode: country === 'CA' ? 'HD60CA' : 'HD60',
    country,
  })

  return (
    <BackgroundImage
      className={cx('text-center', isMobile ? 'p-24' : 'p-20')}
      src={isMobile ? mobileBackgroundImage : descktopBackgroundImage}
      sizes="100vw"
    >
      <Text
        style="h8"
        color="white"
        message={isMobile
          ? messages.treatYourselfMobile
          : {
            ...messages.treatYourselfDesktop,
            values: {
              discount: discountAmount || discountPercent,
            },
          }}
      />

    </BackgroundImage>
  )
}


export default TwelveDaysBanner
