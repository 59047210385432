import React from 'react'
import { useDevice } from 'device'
import links from 'links'

import { cx } from 'helpers'
import { Text } from 'components/dataDisplay'

import { Button, buttonMessages } from 'components/inputs'
import HeaderBannerContainer, { type HeaderBannerContainerProps } from 'compositions/banners/HeaderBannerContainer/HeaderBannerContainer'


export type SubscribeHeaderBannerBaseAbProps = Pick<HeaderBannerContainerProps, 'to' | 'trackParams' | 'loading' | 'onClick'> & {
  title?: Intl.Message
  isStatic?: boolean
}

const SubscribeHeaderBannerBaseAb: React.FC<SubscribeHeaderBannerBaseAbProps> = (props) => {
  const {
    title,
    to = links.subscribe,
    trackParams,
    loading,
    onClick,
    isStatic,
  } = props

  const { isMobile } = useDevice()

  const bannerProps: HeaderBannerContainerProps = {
    className: 'bg-gold-70',
    height: 64,
    'data-testid': 'subscribeBanner',
    loading,
  }

  if (!isStatic) {
    bannerProps.to = to
    bannerProps.trackParams = trackParams
    bannerProps.onClick = onClick
  }

  return (
    <HeaderBannerContainer {...bannerProps}>
      <div className={cx('flex w-full items-center', isMobile ? 'justify-between px-16' : 'justify-center gap-24')}>
        <Text
          message={title}
          style={isMobile ? 'sh6' : 'sh5'}
          html
        />
        <Button
          className="!min-w-auto !px-8"
          size={38}
          title={buttonMessages.checkoutNowSeparate}
        />
      </div>
    </HeaderBannerContainer>
  )
}


export default SubscribeHeaderBannerBaseAb
