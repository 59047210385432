import React from 'react'
import { constants } from 'helpers'
import links from 'links'

import { useSubscribeOffer } from 'modules/subscription'
import SubscribeHeaderBannerBase from 'compositions/banners/SubscribeHeaderBannerBase/SubscribeHeaderBannerBase'

import messages from './messages'


const textByOffer: Partial<Record<SubscriptionModule.OfferType, { cookieName: string, message: Intl.Message}>> = {
  freeProduct: {
    cookieName: constants.cookieNames.freePerfumeCountdown,
    message: messages.freeProduct,
  },
  discount: {
    cookieName: constants.cookieNames.rootCountdown,
    message: messages.discount,
  },
}

type LandingSubscriptionHeaderBannerProps = {
  placement?: 'Header' | 'Under logo'
  isStatic?: boolean
  className?: string
  textClassName?: string
}

// this banner is used on landings and register page
// we show countdowns only fo some
const LandingSubscriptionHeaderBanner: React.FC<LandingSubscriptionHeaderBannerProps> = (props) => {
  const { className, textClassName, placement = 'Header', isStatic } = props

  const {
    isFetching,
    discountText,
    formattedPrice,
    formattedExtraShippingPrice,
    offerType,
    cookieCouponCode,
    couponCode,
    eventDiscountText,
    handleSubscribeClick,
  } = useSubscribeOffer()

  // ATTN we don't show default coupon if banner is static, because it's not saved
  if (isStatic && !cookieCouponCode) {
    return null
  }

  // ATTN we show only limited amount of offers here
  if (!textByOffer[offerType]) {
    return null
  }

  const { cookieName, message } = textByOffer[offerType]

  const title = {
    ...message,
    values: {
      discount: discountText,
      price: formattedPrice,
      extraShippingPrice: formattedExtraShippingPrice,
    },
  }

  return (
    <SubscribeHeaderBannerBase
      className={className}
      textClassName={textClassName}
      title={title}
      loading={isFetching}
      isStatic={isStatic}
      trackParams={{
        placement,
        content: 'Subscribe',
        action: 'link',
        link: links.subscribe,
        discount: eventDiscountText,
        coupon: couponCode,
      }}
      withCountdown
      countdownCookieName={cookieName}
      onClick={handleSubscribeClick}
    />
  )
}


export default LandingSubscriptionHeaderBanner
