import React, { useRef } from 'react'
import cx from 'classnames'
import { Message } from 'intl'
import { useCountdown } from 'hooks'

import messages from './messages'
import s from './Countdown.module.css'


type CountdownProps = {
  cookieName?: string
  withMilliseconds?: boolean
}

const secondsFormat = 'mm [min], ss [sec]'
const millisecondsFormat = 'mm [min], ss.S [sec]'

const Countdown: React.FC<CountdownProps> = ({ cookieName, withMilliseconds = true }) => {
  const countdownRef = useRef<HTMLSpanElement>()

  const { countdownMessage, isExpired } = useCountdown({
    nodeRef: countdownRef,
    cookieName,
    initialTime: 20 * 60 * 1000,
    format: withMilliseconds ? millisecondsFormat : secondsFormat,
    withMilliseconds,
    message: messages.time,
  })

  if (isExpired) {
    return null
  }

  const timeClassName = cx('inline-block', withMilliseconds ? s.milliseconds : s.seconds, withMilliseconds ? 'text-black' : null)

  return (
    <>
      {'. '}
      <span className="inline-block whitespace-nowrap text-left" data-testid="timer">
        <Message value={messages.start} />{' '}
        <span ref={countdownRef} className={timeClassName}>{countdownMessage}</span>
      </span>
    </>
  )
}


export default Countdown
